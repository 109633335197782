<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-card-text class="pt-5 justify-center">
        <v-row
          v-for="detail in dataArrayComputed"
          :key="detail.key"
          class="align-center"
        >
          <v-col
            cols="12"
            md="3"
            class="pt-1 pb-2"
          >
            {{ detail.key }}
          </v-col>
          <v-col
            cols="12"
            md="9"
            class="py-1"
          >
            {{ detail.value }}
          </v-col>
        </v-row>

        <!-- <v-list
          v-if="dataArrayComputed.length"
          subheader
          dense
        >
          <v-list-item
            v-for="detail in dataArrayComputed"
            :key="detail.key"
            dense
            style="min-height: 30px;"
          >
            <v-list-item-content class="py-0 w-content">
              <v-list-item-title v-text="detail.key"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="ma-0 w-action">
              <v-list-item-title
                v-text="detail.value"
              ></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list> -->
      </v-card-text>

      <!-- action buttons -->
      <options-buttom-tab
        :tab-number="tab"
        :is-mode-save-edit="option !== 2"
        :info-required="false"
        :no-cancel="option === 3"
        :no-arrow="true"
        @onCancel="onCancelAction"
        @onSave="onSaveAction"
      >
      </options-buttom-tab>
    </v-form>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'

// import { formatDateSlash } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const menu = ref(false)
    const modal = ref(false)
    const { t } = useUtils()

    const initData = ref({
      cpu_percent: null,
      cpu_temperature: null,
      desynchronization_of_files: null,
      fan_speed_percent: null,
      free_space: null,
      is_recording: null,
      iso_timezone: null,
      last_ping_utc_iso: null,
      last_thumb_utc_iso: null,
      left_thumb_url: null,
      mac_address: null,
      memory_percent: null,
      message: null,
      mode: null,
      nano_id: null,
      right_thumb_url: null,
      status: null,
      stored_chunks: null,
      stored_videos: null,
    })
    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))
    const dataArrayComputed = computed(() => Object.entries(dataComputed.value).map(([key, value]) => ({ key, value })))

    const onCancelAction = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('saveUser', dataComputed.value)
      }
    }

    return {
      form,
      dataComputed,
      dataArrayComputed,
      menu,
      modal,

      // Validations
      required,

      // Methods
      onCancelAction,
      onSaveAction,

      // i18n
      t,

      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.w-content {
  width: 25%;
}

.w-action {
  width: 75%;
}

.list-detail {
  min-width: 600px;
}

.item-detail {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}
.horiz-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
