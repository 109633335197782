<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-row class="justify-center pa-2">
      <v-col
        cols="12"
        md="5"
      >
        <v-card
          color="#1d1d1d"
          class="rounded-lg"
          elevation="15"
        >
          <v-img
            v-if="thumbnails.left_thumbnail"
            :src="thumbnails.left_thumbnail"
            :lazy-src="thumbnails.left_thumbnail"
            eager
            contain
            @click="openImg(thumbnails.left_thumbnail)"
          ></v-img>
          <v-card-title
            class="text-sm"
          >
            {{ dataComputed.field ? dataComputed.field.name : '' }} {{ t('tooltip.left') }}
          </v-card-title>
          <v-card-text>
            {{ t('cameras.date') }}: {{ thumbnails.thumbnail_date }}
          </v-card-text>
          <v-card-text>
            {{ t('cameras.last_date') }}: {{ thumbnails.last_update_date }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <v-card
          color="#1d1d1d"
          class="rounded-lg"
          elevation="15"
        >
          <v-img
            v-if="thumbnails.right_thumbnail"
            :src="thumbnails.right_thumbnail"
            :lazy-src="thumbnails.right_thumbnail"
            eager
            contain
            @click="openImg(thumbnails.right_thumbnail)"
          ></v-img>
          <v-card-title
            class="text-sm"
          >
            {{ dataComputed.field ? dataComputed.field.name : '' }} {{ t('tooltip.right') }}
          </v-card-title>
          <v-card-text>
            {{ t('cameras.date') }}: {{ thumbnails.thumbnail_date }}
          </v-card-text>
          <v-card-text>
            {{ t('cameras.last_date') }}: {{ thumbnails.last_update_date }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="hasPermission(32)"
        cols="12"
        md="6"
      >
        <v-btn
          color="secondary"
          block
          @click="loadThumbnails(dataComputed.id)"
        >
          <v-icon class="mr-2">
            {{ icons.mdiRefresh }}
          </v-icon>
          {{ t('tooltip.refresh') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- action buttons -->
    <options-buttom-tab
      :tab-number="tab"
      :is-mode-save-edit="option !== 2"
      :info-required="option !== 2"
      :no-cancel="option === 3"
      :no-arrow="option === 2"
      :no-save="true"
      @onCancel="onCancelButton"
      @onNextTab="changeTab"
    >
    </options-buttom-tab>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiRefresh, mdiCameraFlipOutline } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { getCamerasThumbnails } from '@api'
import usePermissions from '@core/utils/usePermissions'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const thumbnails = ref({})
    const { t } = useUtils()
    const { hasPermission } = usePermissions()

    const initData = ref({
      camera_detail: null,
      code: null,
      description: null,
      field: {
        name: null,
        facility: {
          name: null,
        },
      },
      field_id: null,
      free_space: null,
      id: null,
      last_thumbnail_date: null,
      last_update_date: null,
      mac_address: null,
      name: null,
      status: null,
      status_str: null,
    })
    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))

    const onCancelButton = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const openImg = url => {
      const imageWindow = window.open('', '_blank')
      imageWindow.document.write(`
        <html>
          <head>
          </head>
          <body
            style="
              align-items: center;
              justify-content: center;
              display: flex;
            "
          >
            <img src="${url}" alt="img" height="auto" width="100%">
          </body>
        </html>
      `)
    }

    const loadThumbnails = async id => {
      const response = await getCamerasThumbnails(id)
      if (response.ok) {
        thumbnails.value = response.data
        emit('changeLastThumbnailDate', thumbnails.value.last_update_date)
        emit('setDetails', thumbnails.value.camera_detail)
      }
    }

    onMounted(async () => {
      await loadThumbnails(dataComputed.value.id)
    })

    return {
      form,
      thumbnails,
      dataComputed,

      // Methods
      onCancelButton,
      changeTab,
      loadThumbnails,
      openImg,
      hasPermission,

      // i18n
      t,

      icons: {
        mdiRefresh,
        mdiCameraFlipOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
// div.v-image__image {
//   background-size: contain !important;
// }

.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
