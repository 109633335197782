<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('cameras.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.mac_address"
              :label="t('cameras.mac_address')"
              placeholder="0A:2B:00:3C:00:4D"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-textarea
              v-model="dataComputed.description"
              :label="t('cameras.description')"
              rows="2"
              dense
              outlined
              auto-grow
              :readonly="option===2"
            ></v-textarea>
          </v-col>

          <v-col
            v-if="option!==1 && userData.role === 'A'"
            cols="12"
            sm="12"
            md="3"
          >
            <v-menu

              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  full-width
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menuDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- <v-col
            v-if="option!==1 && userData.role === 'A'"
            cols="12"
            sm="12"
            md="3"
          >
            <v-btn
              color="warning"
              dark
              block
              @click="asyncCamera(dataComputed)"
            >
              <v-icon
                right
                dark
                class="mr-1"
              >
                {{ icons.mdiCached }}
              </v-icon>
              {{ t('cameras.refresh_chunk') }}
            </v-btn>
          </v-col> -->

          <v-col
            v-if="option!==1 && hasPermission(31)"
            cols="12"
            sm="12"
            md="3"
          >
            <v-btn

              :color="resolveCameraColor(dataComputed.status)"
              dark
              block
              @click="option===1 ? () => {} : changeCameraStatus(dataComputed.id)"
            >
              <v-icon
                right
                dark
                class="mr-1"
              >
                {{ dataComputed.status === 'A' ? icons.mdiArrowUpBold : icons.mdiArrowDownBold }}
              </v-icon>
              {{ dataComputed.status_str }}
            </v-btn>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="option !== 2"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCalendar, mdiCached, mdiMenuDown, mdiArrowUpBold, mdiArrowDownBold } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, macValidator } from '@core/utils/validation'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePermissions from '@core/utils/usePermissions'
import { useUtils } from '@core/libs/i18n'
import { putCameraStatus, getVideoImport } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
    lastThumbnailDate: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const modal = ref(null)
    const isDialogVisible = ref(false)
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const { hasPermission } = usePermissions()

    const date = ref(new Date().toISOString().substr(0, 10))
    const menuDate = ref(false)

    const cameraStatus = ref(null)
    const initData = ref({
      camera_detail: null,
      code: null,
      description: null,
      free_space: null,
      id: null,
      last_thumbnail_date: null,
      last_update_date: null,
      mac_address: null,
      name: null,
      status: null,
      status_str: null,
    })

    const dataComputed = computed(() => (props.dataParams ? props.dataParams : initData.value))
    const computedLocale = computed(() => t('locale'))
    const lastDateComputed = computed(() => props.lastThumbnailDate)

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const onCancelButton = () => {
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const changeCameraStatus = async id => {
      const resp = await putCameraStatus(id)
      cameraStatus.value = resp.data
      dataComputed.value.status = cameraStatus.value.status
      dataComputed.value.status_str = cameraStatus.value.status_str
      emit('setDetails', cameraStatus.value.camera_detail)
    }

    const asyncCamera = async camera => {
      await getVideoImport(date.value, camera.mac_address)
    }

    const resolveCameraColor = status => {
      if (status === 'active' || status === 'A') return 'success'
      if (status === 'inactive' || status === 'I') return 'error'

      return 'primary'
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    return {
      form,
      modal,
      statusOptions,
      cameraStatus,
      isDialogVisible,
      menuDate,
      date,
      userData,

      // computes
      dataComputed,
      computedLocale,
      lastDateComputed,

      // methods
      onSaveAction,
      onCancelButton,
      changeTab,
      changeCameraStatus,
      resolveCameraColor,
      asyncCamera,
      hasPermission,

      // validations
      required,
      macValidator,

      // i18n
      t,

      icons: {
        mdiCalendar,
        mdiCached,
        mdiMenuDown,
        mdiArrowUpBold,
        mdiArrowDownBold,
      },
    }
  },
}
</script>
